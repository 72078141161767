.btnDownloadAll {
  width: 100%;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  padding: 0.5rem 0.25rem;
  border: 1px solid #0044cc33;
  border-radius: 0.375rem;
  text-align: center;
  background-color: #ECF5FE;
  color: #0044CC;
  font-weight: 600;
  cursor: pointer;

  :first-child {
    margin-right: 0.375rem;
  }
}

@primary-color: #0044CC;@font-family: 'Roboto', sans-serif;