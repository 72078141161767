:global(.ant-typography).editableTitleTextArea {
  word-break: break-word;

  &, :global(.ant-input) {
    color: #0044CC;
    font-size: 24px;
    margin: 0;
    left: 0;
    min-height: 30px;
    line-height: 1.3;
     //font-weight: 600;
  }

  :global(.ant-input) {
    &, &:active, &:focus, &:focus-visible {
      padding: 0;
      border: none;
      box-shadow: none;
      outline: none;
    }
  }

  :global(.anticon.anticon-enter) {
    display: none;
  }

  &.placeholder {
    color: #bfbfbf;
  }

  :global(.ant-typography-edit) {
    display: none !important;
  }
}

:global(.ant-form-item.ant-form-item-has-error) :global(.ant-typography).editableTitleTextArea {
  :global(.ant-input) {
    border: none;
    box-shadow: none;
    outline: none;
  }
}

@primary-color: #0044CC;@font-family: 'Roboto', sans-serif;