@import '~styles/index.less';

.svg {
  display: flex;
  align-items: center;

  svg {
    color: white;
  }
}

.dropdown {
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  width: 400px;

  .wrapper {
    &.loading {
      pointer-events: none;
      opacity: 0.5;
    }

    .head {
      border-bottom: 1px solid fade(@color-primary, 20%);
      padding: 5px 20px;
    }

    .body {
      padding: 15px 20px;
    }

    .item {
      padding: 5px;

      .title {
        color: @text-color;

        &:hover {
          color: @color-primary;
          cursor: pointer;
        }
      }
    }
  }
}

.modal {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100 !important;
}

@primary-color: #0044CC;@font-family: 'Roboto', sans-serif;