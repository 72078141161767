@import '~styles/index.less';

.header {
  background-color: #FCFDFE;
  margin: 0 !important;
  padding: 0 16px !important;
  position: relative;
  line-height: 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 68, 204, 0.2);

  .searchBar {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &.dev {
    border-bottom: 4px solid orange;
  }

  .headerWrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    .menuItems {
      display: flex;
      flex: 1 1 auto;
      justify-content: flex-end;
      align-items: center;
      gap: 16px;
    }

    .hMobile{
        @media screen and (max-width: 576px) {
          display: none!important;
        }
    }

    .globalSearch{
      width: 100%;
      @media screen and (max-width: 576px) {
        display: none!important;
      }
    }

    .logo {
      font-family: 'zephyr', serif;
      font-size: 30px;
      line-height: 1;
      color: rgba(0, 68, 204, 0.85);
      padding-left: 8px;
      padding-top: 6px;

      .full {
        display: none;
      }

      @media (min-width: 769px) {
        .short {
          display: none;
        }

        .full {
          display: inline-block;
        }
      }
    }
  }
}

.refreshMessage {
  background-color: #69e35b;
  padding: 6px;
  text-align: center;
}

@primary-color: #0044CC;@font-family: 'Roboto', sans-serif;