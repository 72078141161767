.container.fullPage {
  display: flex;
  flex-direction: column;
  //min-height: 530px;
  height: 100%;
  padding: 12px 12px 0;

  @media (min-width: 992px) and (min-height: 550px) {
    max-height: calc(100vh - 64px);
  }

  > :global(.ant-tabs), > .tableContainer {
    flex: 1 1 auto;

    > :global(.ant-tabs-content-holder) {
      height: 100%;

      > :global(.ant-tabs-content) {
        height: 100%;

        > :global(.ant-tabs-tabpane) {
          height: 100%;

          > .tableContainer {
            height: 100%;
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }

  > .tableContainer {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-top: 12px;
  }

  > .tableContainer > :global(.ant-table-wrapper), > :global(.ant-tabs > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane > div > .ant-table-wrapper) {
    max-height: 100%;
    min-height: 95px;

    @media (min-width: 768px) {
      min-height: 75px;
    }

    > :global(.ant-spin-nested-loading) {
      height: 100%;

      > :global(.ant-spin-container) {
        height: 100%;

        > :global(.ant-table) {
          height: 100%;

          > :global(.ant-table-container) {
            height: 100%;
          }
        }
      }
    }
  }

  &.withScroll {
    height: auto;
  }
}

.footer {
  padding: 12px 0 62px;

  @media (min-width: 768px) {
    padding: 12px 26px 12px 0;
  }
}

@primary-color: #0044CC;@font-family: 'Roboto', sans-serif;