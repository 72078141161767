@import (reference) '../../../../styles/index.less';

.link {
  display: block;
  color: @text-color;
  padding: 0.4rem 0.2rem 0.4rem;
  border-bottom: 1px solid rgba(0, 68, 204, 0.10);

  &:hover, &:active {
    color: @text-color;
  }

  &:hover {
    background-color: #fafafa;
  }
}

.title {
  margin: 0 !important;
}

.subTitle {
  color: @text-color-secondary;
  font-size: 12px;
}

.colProject, .colHours, .colStatus {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.colResponsible {
  order: 2;
  flex: none;
}

.colProject {
  order: 1;
  width: 100%;
  overflow: hidden;
}

.colResponsible + .colProject {
  flex: 1 1 50%;
}

.colHours {
  order: 3;
  flex: 1 1 50%;
}

.colStatus {
  order: 4;
  flex: 0 1 auto;
  text-align: right;
}

@media (min-width: 768px) {
  .colResponsible {
    order: 1;
  }

  .colProject {
    order: 2;
    flex: 1 1 auto;
    width: auto;
  }

  .colHours {
    flex: none;
    width: 160px;
  }

  .colStatus {
    flex: none;
    width: 140px;
  }

  .rowExtraInfo {
    flex-wrap: nowrap;
  }
}

@media (min-width: 992px) {
  .colProject {
    flex: none;
    width: 200px;
  }

  .rowItem {
    flex-wrap: nowrap;
  }
}


@primary-color: #0044CC;@font-family: 'Roboto', sans-serif;