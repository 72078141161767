.sider {
    padding: 10px;
}
.content {
  width: 100%;
  height: calc(100vh - 64px);
  background: #FEFEFE;
  overflow: auto;
}

@primary-color: #0044CC;@font-family: 'Roboto', sans-serif;