.task-card {
  display: flex;
  flex-direction: column;
  height: 100%;

  .task-card--header {
    border-bottom: 1px solid rgba(0,68,204,.1);
    padding-bottom: 8px;

    .task-card--header-col-title {
      flex: 1 1 0;
      display: inline-flex;
      gap: 4px;
    }

    .task-card--header-col-users {
      display: none;
      justify-content: center;
    }

    @media (min-width: 576px) {
      .task-card--header-col-users {
        display: flex;
      }
    }
  }

  .task-card--body {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex: 1 1 auto;
    margin-right: -16px;
    padding-right: 16px;
  }

  .col-responsible {
    flex: 1 1 auto;
  }

  @media (min-width: 768px) {
    .col-responsible {
      flex: none;
      max-width: 100px;
      width: 100%;
    }
  }
}

@primary-color: #0044CC;@font-family: 'Roboto', sans-serif;