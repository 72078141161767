@import '~styles/index.less';

.logo {
  text-align: center;
  padding-bottom: 24px;
}

.container__login {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  justify-content: center;
  align-items: center;
  background: rgb(255, 255, 255);
  background: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(0, 68, 204, 0.3) 100%);
}

.content {
  padding: 32px 0;
  width: 100%;
  max-width: 400px;
}

.container__message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: normal;

  &__text {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
  }

  &__tip {
    font-weight: 700;
    font-size: 14px;
    margin-top: 80px;
  }

  &__title {
    color: #f5222d;
    font-size: 32px;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    position: fixed;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }

  &__action {
    text-decoration-line: underline;
    color: #0044cc;
    position: fixed;
    bottom: 20px;
    left: 20px;
    cursor: pointer;
  }

  @media screen and (max-width: 722px) {
    &__title {
      top: 20px;
      width: 90%;
    }

    &__text,
    &__tip {
      padding: 24px;
      text-align: center;
    }
  }
}

@primary-color: #0044CC;@font-family: 'Roboto', sans-serif;