@import (reference) './index.less';

.billing-portal {
  min-height: 100vh;
  width: 100%;
  background-color: @color-primary;
  padding: 10px 20px;
  padding-bottom: 50px;

  &.min {
    min-height: 0;
  }

  .ant-typography {
    color: white !important;
    text-align: center !important;
  }

  &__nav {
    h2 {
      font-family: 'zephyr';
      font-size: 32px;
      color: white;
      margin-bottom: 0 !important;
    }
  }

  .large-headline {
    font-weight: bold;
    font-size: 62px;
  }

  .main-headline {
    font-size: 32px;
    text-align: center !important;
    color: white !important;
    line-height: 120%;
  }

  .step-card {
    display: flex;
    flex-direction: column;
    align-items: center;

    .step-card-body {
      width: 600px;

      display: flex;
      flex-direction: column;
      align-items: center;

      &.large {
        width: 1000px;
      }

      label {
        color: white !important;
      }

      .form-lg {
        input {
          height: 80px !important;
          border-radius: 0 !important;
          font-size: 32px !important;
        }
      }
    }

    &__actions {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      color: white !important;
      display: flex;
      justify-content: space-between;
      padding: 10px 20px;
      div {
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  button.main-btn {
    height: 64px;
    background-color: @color-accent !important;
    outline: none;
    border: none;
    padding-right: 40px;
    padding-left: 40px;
    transition: all 0.2s !important;

    &:hover {
      background-color: fade(@color-accent, 80%) !important;
    }

    span {
      color: #000 !important;
      font-size: 24px;
    }
  }

  button.btn-small {
    height: 32px;
    padding-right: 20px;
    padding-left: 20px;
    color: black !important;

    span {
      font-size: 14px !important;
      color: black !important;
    }
  }

  .ant-alert-error {
    background-color: red;
    border: none !important;
    color: white !important;
    text-align: center;

    .ant-alert-message {
      color: white !important;
    }
  }
}

.step-card-modal {
  .ant-modal-content {
    background-color: @color-primary;
    border: 1px solid white;
  }

  .ant-modal-close-x {
    color: white !important;
  }
}

.ant-alert.ant-alert-error {
  border-radius: 8px;
  border-color: @color-red;
}
