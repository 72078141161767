@media (max-width: 550px) {
  .dropdownOverlay {
    margin-left: 12px;
    margin-right: 12px;
  }
}

.dropdownOverlay {
  background: #0044CC;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  width: calc(100vw - 24px);
  max-width: 500px;
  height: 500px;
  margin-top: 16px;
}

.dropdownHeader, .dropdownFooter {
  padding: 16px 24px;
  color: white;
  line-height: 1;
  display: flex;
  justify-content: space-between;

  button, button:hover, button:focus {
    color: white;
    padding-top: 0;
    padding-bottom: 0;
  }

  :global(.ant-radio-button-wrapper) {
    &:hover {
      color: #22c55e;
    }
  }

  :global(.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)) {
    background-color: #22c55e;
    border-color: #22c55e;

    &::before {
      background-color: #22c55e;
    }

    &:hover {
      background-color: #4ade80;
      border-color: #4ade80;
    }

    &:hover::before {
      background-color: #4ade80;
    }

    &:active {
      background-color: #16a34a;
      border-color: #16a34a;
    }

    &:active::before {
      background-color: #16a34a;
    }
  }
}

.dropdownBody {
  overflow: auto;
  flex: 1 1 0;
  background-color: white;
  padding: 10px;
}

.dropdownCard {
  display: flex;
  padding: 10px 14px;
  gap: 6px;
  align-items: center;
  transition: background-color ease-in-out 200ms;
  border-radius: 8px;
  margin-bottom: 10px;

  > :first-child {
    flex: 1 1 auto;
    word-break: break-word;
    display: flex;
    gap: 14px;
    align-items: center;
    text-align: left;

    > :first-child {
      flex: 1 1 auto;
    }

    > :nth-child(2) {
     flex: 0 0 auto;
    }
  }

  article, span {
    font-size: 13px;
  }

  article {
    overflow: hidden;
    max-height: 41px;
  }

  &:hover {
    background: rgba(0,0,0,.06);
  }
}

.dropdownCard:last-child {
  margin-bottom: 0;
}

.dropdownCardUnRead {
  & {
    background: #d6e8ff;
  }

  &:hover {
    background: #bfdbfe;
  }
}

.dropdownCardTitle {
  font-weight: 500;
  margin-bottom: 2px;
}

@primary-color: #0044CC;@font-family: 'Roboto', sans-serif;