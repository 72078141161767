.overlay {
  background-color: white;
  box-shadow: rgba(17, 12, 46, 0.15) 0 48px 100px 0;
  border-radius: 3px;

  .item {
    padding: 20px;

    &:nth-child(even) {
      background: rgba(0, 68, 204, 0.05) !important;
      .data {
        &:hover {
          background-color: rgba(0, 68, 204, 0.2);
          cursor: pointer;
        }
      }
    }

    .data {
      display: block;
      padding: 8px 0 8px 10px;
      transition: all 0.2s;
      &:hover {
        background-color: #ececec;
        cursor: pointer;
      }
    }
  }
}

.inputWrapper {
  .input {
    width: 100%;
  }
}

@primary-color: #0044CC;@font-family: 'Roboto', sans-serif;