@import (reference) '../../../../styles/index.less';

.sidebar {
  background: #FCFDFE;
  border-right: 1px solid rgba(0, 68, 204, 0.2);

  :global(.ant-menu) {
    background: #FCFDFE;
    border: none;

    &:global(.ant-menu-root) {
      padding-top: 8px;
    }

    :global(.ant-menu-item), :global(.ant-menu-submenu-title) {
      width: 100%;
      margin: 0;
      padding-left: 24px !important;
    }

    :global(.ant-menu-item), :global(.ant-menu-submenu) {
      background-color: inherit;
      margin-bottom: 8px;

      a {
        color: inherit;
      }

      &, :global(.ant-menu-submenu-arrow) {
        color: #1D1D1D;
      }

      :global(.ant-menu-submenu-title) {
        color: inherit;
      }

      &, :global(.ant-menu-item-icon) {
        font-size: 18px;
        transition: all 0.1s;
        font-weight: 300;
      }

      :global(.ant-menu-item-icon) + span {
        margin-left: 14px;
      }

      &:hover, :global(.ant-menu-submenu-title):hover {
        > :global(.ant-menu-item-icon), > :global(.ant-menu-title-content) .itemLabel, > .itemLabel {
          opacity: 0.5;
        }
      }

      &:active, :global(.ant-menu-submenu-title):active {
        background: inherit;
      }

      &:after {
        content: none;
      }
    }

    :global(.ant-menu-item.app-menu-item-selected), :global(.app-menu-submenu-selected:not(.ant-menu-submenu-open)) {
      &, :global(.ant-menu-submenu-arrow) {
        color: @color-primary;
      }
    }

    :global(.ant-menu-sub) {
      :global(.ant-menu-title-content) {
        font-size: 16px;
      }

      :global(.ant-menu-item) {
        margin: 0;
        padding-left: 48px !important;
      }
    }
  }

  :global(.ant-badge) {
    padding-left: 12px;
  }

  .searchItem:global(.ant-menu-item) {
    height: auto;
    padding: 10px !important;
    margin-bottom: 0;

    :global(.ant-menu-title-content) {
      overflow: visible;
    }
  }
}

.simpleBar {
  max-height: calc(100vh - 82px);
}

.version {
  position: absolute;
  bottom: 0;
  left: 6px;
  font-size: 12px;
  color: #9A9A9A;
}

@primary-color: #0044CC;@font-family: 'Roboto', sans-serif;