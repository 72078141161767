.select:global(.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
  cursor: default ;

  input {
    cursor: default;
  }
}

.select :global(.ant-select-selector) {
  :global(.ant-select-selection-item) {
    .secondaryLabel {
      color: #9A9A9A;
    }
  }
}

.select:not(.onlyAvatar):global(.ant-select-single.ant-select-open) :global(.ant-select-selection-item){
  display: none;
}

.select.onlyAvatar {
  :global(.ant-select-selector) {
    :global(.ant-select-selection-item) {
      .nameCol {
        display: none;
      }

      :global(.ant-avatar) {
        margin-top: 4px;
      }
    }

    :global(.ant-select-selection-overflow-item:not(.ant-select-selection-overflow-item-rest):not(.ant-select-selection-overflow-item-suffix)) {
      padding: 0;
      margin: 0;
      background: none;
      border: none;
      height: auto;
      line-height: 1;

      &:not(:first-child) {
        margin-left: -7px;
      }

      :global(.ant-avatar) {
        border: 1px solid #FFFFFF;
      }
    }

    :global(.ant-select-selection-overflow-item.ant-select-selection-overflow-item-rest) {
      margin-left: -7px;

      :global(.ant-select-selection-item) {
        border: 1px solid #FFFFFF;
        border-radius: 50% !important;
        width: 32px;
        font-size: 12px;
        padding: 0;
        justify-content: center;
      }
    }

    :global(.ant-select-selection-overflow-item + .ant-select-selection-overflow-item-suffix) {
      margin-left: 4px;
    }
  }
}

.avatarContainer {
  position: relative;

  .removeUser {
    position: absolute;
    top: -3px;
    left: -3px;
    font-size: 10px;
    color: white;
    background-color: red;
    border: 1px solid white;
    border-radius: 100%;
    padding: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    cursor: pointer;
  }
}

@media (max-width: 575px) {
  :global(.ant-form.form-nowrap .ant-form-item) .select:global(.ant-select) :global(.ant-select-selection-item > .ant-row) {
    justify-content: end;

    .nameCol {
      flex: none !important;
    }
  }
}

@primary-color: #0044CC;@font-family: 'Roboto', sans-serif;