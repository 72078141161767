@import (reference) '../../../../styles/index.less';

.dropdownMenu {
  min-width: 140px;

  :global(.ant-dropdown-menu-item-icon) {
    font-size: 18px;
  }
}

.menuTitle {
  font-family: 'zephyr', serif;
  font-size: 30px;
  line-height: 1;
  color: rgba(0, 68, 204, 0.85);
}

.drawerMenu {
  :global(.ant-drawer-content-wrapper) {
    max-width: 100%;

    :global(.ant-drawer-header) {
      background: #fcfdfe;
    }

    :global(.ant-drawer-body) {
      padding: 0;
    }

    :global(.ant-drawer-footer) {
      padding: 2px 26px;
      font-size: 12px;
      color: #9A9A9A;
    }
  }
}

@primary-color: #0044CC;@font-family: 'Roboto', sans-serif;