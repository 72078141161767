@import '~styles/index.less';


.timer {
  height: 20px;
  display: flex;
  align-items: center;

  .value {
    &.running {
      span {
        animation: blink 1s step-start infinite;
      }
    }
  }


  svg:hover {
    cursor: pointer;
    color: @color-primary;
  }

  &.saving {
    opacity: 0.3;
    pointer-events: none;
  }

  &.global {
    cursor: pointer;

    svg:hover {
      color: #9A9A9A;
    }
  }
}

.dropdown {
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid fade(@color-primary, 20%);

  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .body {
    padding: 20px;
  }

  .head {
    border-bottom: 1px solid fade(#000, 10%);
    padding: 10px 20px;

    span {
      font-size: 16px !important;
    }
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

// @keyframes blink{
//     0%{opacity: 0;}
//     50%{opacity: 1;}
//     100%{opacity: 0;}
// }

.modal {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100 !important;
}

.suffix {
  svg {
    color: grey !important;
    opacity: 0.6;
  }
}

@primary-color: #0044CC;@font-family: 'Roboto', sans-serif;