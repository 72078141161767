.like {
  position: relative;
  cursor: pointer;
  font-size: 16px;
  color: white;
}

.likeOutline {
  color: transparent !important;
  text-shadow: 0 0 1px gray, -1px -1px 1px gray, -1px 1px 1px gray, 1px 1px 1px gray, 1px -1px 1px gray;
  position: relative;

  &:before {
    content: attr(data-like);
    position: absolute;
    width: 100%;
    text-shadow: 0 0 0 #fff;
  }
}

@primary-color: #0044CC;@font-family: 'Roboto', sans-serif;